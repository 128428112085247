import React from 'react';
import Form from 'react-bootstrap/Form';
import OptionalLabel from './OptionalLabel';

const SchoolDetailsFormGroups = ({
  isLoading,
  isDirty,
  schoolName,
  setSchoolName,
  isSchoolNameValid,
  dismissalTime,
  setDismissalTime,
  grade,
  setGrade,
  pickupDaysPerWeek,
  setPickupDaysPerWeek,
}) => (
  <React.Fragment>
    <Form.Group>
      <Form.Label column={true}>School name</Form.Label>
      <Form.Control
        required
        disabled={isLoading}
        type="text"
        value={schoolName}
        onChange={(e) => setSchoolName(e.target.value)}
        isInvalid={isDirty && !isSchoolNameValid}
        isValid={isDirty && isSchoolNameValid}
      />
      <Form.Control.Feedback type="invalid">
        School name is required.
      </Form.Control.Feedback>
    </Form.Group>

    <Form.Group>
      <Form.Label column={true}>
        Dismissal time
        <OptionalLabel />
      </Form.Label>
      <Form.Control
        type="time"
        disabled={isLoading}
        value={dismissalTime}
        onChange={(e) => setDismissalTime(e.target.value)}
      />
    </Form.Group>

    <Form.Group>
      <Form.Label column={true}>
        Grade
      </Form.Label>
      <Form.Select
        disabled={isLoading}
        value={grade}
        onChange={(e) => setGrade(e.target.value)}
      >
        <option value={1}>1st</option>
        <option value={2}>2nd</option>
        <option value={3}>3rd</option>
        <option value={4}>4th</option>
        <option value={5}>5th</option>
        <option value={6}>6th</option>
        <option value={7}>7th</option>
      </Form.Select>
    </Form.Group>

    <Form.Group>
      <Form.Label column={true}>
        How many days per week will your child need pickup?
      </Form.Label>
      <Form.Select
        disabled={isLoading}
        value={pickupDaysPerWeek}
        onChange={(e) => setPickupDaysPerWeek(e.target.value)}
      >
        <option value={2}>2 days per week</option>
        <option value={3}>3 days per week</option>
        <option value={4}>4 days per week</option>
        <option value={5}>5 days per week</option>
      </Form.Select>
    </Form.Group>
  </React.Fragment>
);

export default SchoolDetailsFormGroups;
