import { motion, AnimatePresence } from 'framer-motion';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const SubmitButton = ({ isLoading }) => (
  <Button
    disabled={isLoading}
    className="w-100"
    variant="primary"
    size="lg"
    type="submit"
  >
    <AnimatePresence mode="sync" initial={false}>
      {isLoading ? (
        <motion.div {...motionProps}>
          <Spinner
            aria-label="Loading..."
            color="white"
            as="span"
            animation="border"
            size="sm"
            role="status"
          />
        </motion.div>
      ) : (
        <motion.span {...motionProps}>
          Submit
        </motion.span>
      )}
    </AnimatePresence>
  </Button>
);

export default SubmitButton;
