import Container from 'react-bootstrap/Container';
import { Outlet } from 'react-router-dom';

const App = () => (
  <Container>
    <Outlet />
  </Container>
);

export default App;
