import { useState } from 'react';

const useSchoolDetailsFormGroups = () => {
  const [schoolName, setSchoolName] = useState('');
  const isSchoolNameValid = Boolean(schoolName);
  const [dismissalTime, setDismissalTime] = useState('');
  const [grade, setGrade] = useState('1');
  const [pickupDaysPerWeek, setPickupDaysPerWeek] = useState('2');

  return {
    schoolName,
    setSchoolName,
    isSchoolNameValid,
    dismissalTime,
    setDismissalTime,
    grade,
    setGrade,
    pickupDaysPerWeek,
    setPickupDaysPerWeek,
  };
};

export default useSchoolDetailsFormGroups;
