import { useCallback } from 'react';
import LeadForm from '../components/LeadForm';
import useLeadForm from '../hooks/useLeadForm';
import { submitLead } from '../services/api';

const LeadFormPage = () => {
  const {
    isLoading,
    isDirty,
    firstName,
    setFirstName,
    isFistNameValid,
    lastName,
    setLastName,
    isLastNameValid,
    email,
    setEmail,
    isEmailValid,
    location,
    setLocation,
    phoneNumber,
    setPhoneNumber,
    isPhoneNumberValid,
    dob,
    setDob,
    setIsLoading,
    setIsDirty,
  } = useLeadForm();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    const isValid = Boolean(
      isFistNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isPhoneNumberValid &&
      location
    );

    setIsDirty(true);

    if (!isValid) {
      return;
    }

    setIsLoading(true);

    const data = {
      firstName,
      lastName,
      email,
      location,
      phoneNumber,
      dob,
    };

    try {
      const response = await submitLead(data);

      console.log('=== Response ===');
      console.log(response);
      console.log('================');

      window.parent.postMessage(response, '*');
    } catch (e) {
      console.error(e);
      alert('Failed to submit form, please contact us directly.');
      setIsLoading(false);
    }
  }, [
    isLoading,
    isFistNameValid,
    isLastNameValid,
    isEmailValid,
    isPhoneNumberValid,
    setIsDirty,
    setIsLoading,
    firstName,
    lastName,
    email,
    location,
    phoneNumber,
    dob,
  ]);

  return (
    <LeadForm
      isLoading={isLoading}
      isDirty={isDirty}
      firstName={firstName}
      setFirstName={setFirstName}
      isFistNameValid={isFistNameValid}
      lastName={lastName}
      setLastName={setLastName}
      isLastNameValid={isLastNameValid}
      email={email}
      setEmail={setEmail}
      isEmailValid={isEmailValid}
      location={location}
      setLocation={setLocation}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      isPhoneNumberValid={isPhoneNumberValid}
      dob={dob}
      setDob={setDob}
      handleSubmit={handleSubmit}
    />
  );
}

export default LeadFormPage;
