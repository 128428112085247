import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import './styles/_global.scss';
import App from './App';
import AfterSchoolProgramFormPage from './pages/AfterSchoolProgramFormPage';
import ErrorPage from './pages/ErrorPage';
import LeadFormPage from './pages/LeadFormPage';

const root = ReactDOM.createRoot(document.getElementById('endr-forms-ui'));
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/after-school-program',
        element: <AfterSchoolProgramFormPage />,
      },
      {
        path: '/lead',
        element: <LeadFormPage />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
