import phone from 'phone';
import { useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import schools from '../constants/schools';
import OptionalLabel from './OptionalLabel';
import SubmitButton from './SubmitButton';

const LeadForm = ({
  isLoading,
  isDirty,
  firstName,
  setFirstName,
  isFistNameValid,
  lastName,
  setLastName,
  isLastNameValid,
  email,
  setEmail,
  isEmailValid,
  location,
  setLocation,
  phoneNumber,
  setPhoneNumber,
  isPhoneNumberValid,
  dob,
  setDob,
  handleSubmit,
  children = null,
}) => {
  const handlePhoneNumberChange = useCallback((e) => {
    const value = e.target.value;
    const parsed = phone(value);
    const { isValid } = parsed;

    if (!isValid) {
      setPhoneNumber(value);
      return;
    }

    const { phoneNumber: formattedStr, countryCode } = parsed;
    let valueToSet = formattedStr;

    if (countryCode === '+1') {
      valueToSet = formattedStr.replace('+1', '');
      valueToSet = valueToSet.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }

    setPhoneNumber(valueToSet);
  }, [setPhoneNumber]);

  return (
    <Form
      noValidate
      onSubmit={handleSubmit}
    >
      <Form.Group>
        <Form.Label column={true}>First name</Form.Label>
        <Form.Control
          required
          type="text"
          value={firstName}
          autoComplete="given-name"
          onChange={(e) => setFirstName(e.target.value)}
          isInvalid={isDirty && !isFistNameValid}
          isValid={isDirty && isFistNameValid}
          disabled={isLoading}
        />
        <Form.Control.Feedback type="invalid">
          First name is required.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label column={true}>Last name</Form.Label>
        <Form.Control
          required
          type="text"
          value={lastName}
          autoComplete="family-name"
          onChange={(e) => setLastName(e.target.value)}
          isInvalid={isDirty && !isLastNameValid}
          isValid={isDirty && isLastNameValid}
          disabled={isLoading}
        />
        <Form.Control.Feedback type="invalid">
          Last name is required.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label column={true}>Email</Form.Label>
        <Form.Control
          required
          type="email"
          value={email}
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
          isInvalid={isDirty && !isEmailValid}
          isValid={isDirty && isEmailValid}
          disabled={isLoading}
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid email address.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label column={true}>Phone number</Form.Label>
        <Form.Control
          type="tel"
          value={phoneNumber}
          autoComplete="tel"
          onChange={handlePhoneNumberChange}
          isInvalid={isDirty && !isPhoneNumberValid}
          isValid={isDirty && isPhoneNumberValid}
          disabled={isLoading}
        />
        <Form.Control.Feedback type="invalid">
          Please provide a valid phone number.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label column={true}>Preferred location</Form.Label>
        <Form.Select
          required
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          isInvalid={isDirty && !location}
          isValid={isDirty && location}
          disabled={isLoading}
        >
          <option value="" disabled>
            Choose location
          </option>

          {Object.keys(schools).map((location, i) => {
            const value = schools[location];
            return (
              <option
                key={`location-${i}`}
                value={value}
              >
                {location}
              </option>
            );
          })}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Please select a location.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label column={true}>
          Date of birth
          <OptionalLabel />
        </Form.Label>
        <Form.Control
          type="date"
          autoComplete="bday"
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          disabled={isLoading}
        />
      </Form.Group>

      {children}

      <Form.Group>
        <p className="m-0 pt-4 pb-1 small text-secondary">
          <em>
            By submitting this form, I agree to receive communication from Endurance Taekwondo using any contact info I provide.
          </em>
        </p>
      </Form.Group>

      <SubmitButton isLoading={isLoading} />
    </Form>
  );
}

export default LeadForm;
